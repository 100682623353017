import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { OkDialog } from './core/dialogs/ok.dialog/ok.dialog';
import { OkCancelDialog } from './core/dialogs/ok-cancel.dialog/ok-cancel.dialog';

import { RecaptchaModule } from 'ng-recaptcha';
import { FooterComponent } from './footer/footer.component';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    OkDialog,
    OkCancelDialog,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    RecaptchaModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    OkDialog,
    OkCancelDialog
  ]
})
export class AppModule { }
