import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    text: string;
    button: string;
    buttonValue: string
}

@Component({
    selector: 'ok-cancel-dialog',
    templateUrl: 'ok-cancel.dialog.html',
    styleUrls: ['ok-cancel.dialog.scss']
})
export class OkCancelDialog {

    constructor(
        public dialogRef: MatDialogRef<OkCancelDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    closeDialog(option?: boolean): void {
        this.dialogRef.close(option);
    }
}