import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// import { ToastMessagesComponent } from './toast-messages/toast-messages.component';
import { MatInputModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatNativeDateModule, MatDatepickerModule, MatButton, MatButtonModule, MatDialogModule, MatButtonToggleModule, MatPaginatorIntl, MatRadioModule } from '@angular/material';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AlertsModule } from './alerts/alerts.module';
import { PaginatorIntlService } from './paginator';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MatInputModule,
    MatTableModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    AlertsModule,
    MatRadioModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl, useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ]
})
export class SharedModule { }
