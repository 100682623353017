import { Injectable } from '@angular/core';

export class Alert {
    message: string;
    type: string;
    value: any;
    id: number;

    constructor(message, type, value = '') {
        this.message = message;
        this.type = type || 'info';
        this.value = value;
    }
}

@Injectable({
    providedIn: 'root'
})


export class AlertsService {
    alerts = [];
    private _smallMenu = false;
    constructor() {}

    sendMessage(message: string, type: string, value: any = ''): void {
        this.alerts.pop();
        const newAlert = new Alert(message, type, value);
        this.alerts.unshift(newAlert);
        // if (type === 'success') {
        //     setTimeout(() => {
        //         this.alerts.pop();
        //     }, 3000);
        // }
    }

    getAlerts(): Alert[] {
        return this.alerts;
    }

    dismissMessage(): void {
      this.alerts.pop();
    }

    set smallMenu(val) {
        this._smallMenu = val;
    }
    get smallMenu() {
        return this._smallMenu;
    }
}
