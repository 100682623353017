import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertsService } from '../services/alerts.service';
import { OkCancelDialog } from '../dialogs/ok-cancel.dialog/ok-cancel.dialog';
import { MatDialog } from '@angular/material';
import { StorageService } from '../services/storage.service';
import { OkDialog } from '../dialogs/ok.dialog/ok.dialog';

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private alertService: AlertsService,
    private dialog: MatDialog,
    private storageService: StorageService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (!request.url.includes('openstreetmap')) {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 500 && error.error.exceptionType == "MAILING_SERVICE_PROBLEM") {
          this.alertService.sendMessage('error.MAILING_SERVICE_PROBLEM', 'error');
          return throwError(error);
        } else if (error.url.includes("login")) {
          if (error.status === 401) {
            if (error.error.exceptionType == "BAD_CREDENTIALS") {
              this.alertService.sendMessage('error.BAD_CREDENTIALS', 'error');
              return throwError(error);
            } else if (error.error.exceptionType == "USER_BLOCKED") {
              this.alertService.sendMessage('error.USER_BLOCKED', 'error');
              return throwError(error);
            } else if (error.error.exceptionType == "USER_NOT_ACTIVATED") {
              return throwError(error);
            } else {
              this.alertService.sendMessage('error.global', 'error', error.status);
              return throwError(error);
            }
          }
        } else if (error.status === 401) {
          this.storageService.logout();
          return throwError(error);
        } else if (error.url.includes("register")) {
          if (error.status === 409) {
            return throwError(error);
          } else if (error.status === 404) {
            this.alertService.sendMessage('error.invalidToken', 'error');
            return throwError(error);
          } else if (error.status === 406) {
            this.alertService.sendMessage('error.INVALID_RE_CAPTCHA', 'error');
            return throwError(error);
          } else if (error.status === 400) {
            if (error.error.errors && error.error.errors.length > 0) {
              let differentPasswords = false;
              error.error.errors.forEach(er => {
                if (er.code == "PasswordMatches") {
                  differentPasswords = true;
                }
              });
              if (differentPasswords) {
                this.alertService.sendMessage('error.differentPasswords', 'error');
                return throwError(error);
              }
            }
          }
        } else if (error.url.includes("user/reset_password")) {
          if (error.status === 404) {
            this.alertService.sendMessage('error.emailNotExists', 'error');
            return throwError(error);
          }
        } else if (error.url.includes('user/create')) {
          if (error.status === 409) {
            if (error.error.exceptionType == "RESOURCE_ALREADY_EXIST") {
              this.openOkDialog('message.error.USER_EXISTS');
              return throwError(error);
            }
          }
        } else if (error.url.includes('file/create_from_kml')) {
          if (error.status === 406) {
            if (error.error.exceptionType == "IMPORTED_GEOMETRIES_EXCEPTION") {
              return throwError(error);
            }
          }
        } else if (error.url.includes('/check')) {
          if (error.status === 406) {
            return throwError(error);
          }
        } else if (error.url.includes('update_password')) {
          if (error.status === 412) {
            this.alertService.sendMessage('error.invalidCurrentPassword', 'error');
            return throwError(error);
          } else if (error.status === 400) {
            this.alertService.sendMessage('error.passwordsDontMatch', 'error');
            return throwError(error);
          }
        }
        this.alertService.sendMessage('error.global', 'error', error.status);
        return throwError(error);
      })
    );
  }

  openOkDialog(text: string, icon?: string, textSecond?: string): void {
    const dialogRef = this.dialog.open(OkDialog, {
      width: '500px',
      data: { text: text, icon: icon, textSecond: textSecond },
      panelClass: 'custom-ok-dialog'
    });
  }
}
