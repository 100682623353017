import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(
        private router: Router
    ) { }

    private storageSub = new Subject<string>();

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }

    setItem(key: string, data: any) {
        localStorage.setItem(key, data);
        this.storageSub.next('changed');
    }

    getItem(key: string) {
        return localStorage.getItem(key);
    }

    removeItem(key) {
        localStorage.removeItem(key);
        this.storageSub.next('changed');
    }

    isLoggedIn() {
        return localStorage.getItem('loggedIn');
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    getCurrentUser(): JSON {
        try {
            return JSON.parse(localStorage.getItem('user'));
        } catch (e) {
            this.logout();
            return undefined;
        }
    }

    getRole(): string {
        let user = this.getCurrentUser();
        if (user) {
            return user['role'];
        }
        return undefined;
    }
}
