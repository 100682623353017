import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material';


@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        TranslateModule
    ],
    exports: [AlertsComponent],
    declarations: [AlertsComponent]
})

export class AlertsModule {}
