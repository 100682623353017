import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';

export interface DialogData {
    text: string;
    icon: string;
    textSecond: string
}

@Component({
    selector: 'ok-dialog',
    templateUrl: 'ok.dialog.html'
})
export class OkDialog {
    environment = environment;
    constructor(
        public dialogRef: MatDialogRef<OkDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }
}