import { Component, OnInit } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  environment;

  constructor(
    public storageService: StorageService
  ) { }

  ngOnInit() {
    this.environment = environment;
  }

  get isAdmin(): boolean {
    return this.storageService.getRole() === 'ADMIN';
  }
}
