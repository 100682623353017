import { Injectable } from '@angular/core';
import { CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

@Injectable()
export class GuestGuard implements CanLoad, CanActivate {
    constructor(
        private router: Router,
        private storageService: StorageService
    ) {}
    canLoad(
        route: Route
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.storageService.getItem('loggedIn')) {
            return true;
        }
        this.router.navigate(['/map']);
        return false;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.storageService.getItem('loggedIn')) {
            return true;
        }
        this.router.navigate(['/map']);
        return false;
    }

}
