import { Component, OnInit, Input } from '@angular/core';
import { AlertsService } from 'src/app/core/services/alerts.service';
import { Router, RouterEvent, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  public alertsArray;
  @Input() isLogin;
  constructor(
    private alertService: AlertsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.alertsArray = this.alertService.getAlerts();

    // Remove alert from view when user navigates to different page
    this.router.events.subscribe( e => {
      if (e instanceof NavigationStart) {
        this.alertService.dismissMessage();
      }
    });
  }

  dismissMessage(): void {
    this.alertService.dismissMessage();
  }

}
