import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ngn-ta';
  spinkit;
  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.initTranslate();
    this._document.getElementById('appFavicon').setAttribute('href', environment.properties.favicon);
  }

  initTranslate() {
    this.translate.setDefaultLang('de');
  }
}
