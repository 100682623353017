import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestGuard } from './core/guards/guest.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';


const routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginModule', canActivate: [GuestGuard] },
  { path: 'map', loadChildren: './map/map.module#MapModule', canLoad: [AuthGuard] },
  { path: 'profile', loadChildren: './profile/profile.module#ProfileModule', canLoad: [AuthGuard] },
  { path: 'update-password', loadChildren: './update-password/update-password.module#UpdatePasswordModule', canActivate: [AuthGuard] },
  { path: 'networks', loadChildren: './networks/networks.module#NetworksModule', canLoad: [AuthGuard, AdminGuard] },
  { path: 'admin-map', loadChildren: './admin-map/admin-map.module#AdminMapModule', canLoad: [AuthGuard, AdminGuard] },
  { path: 'users-management', loadChildren: './management-users/management-users.module#ManagementUsersModule', canLoad: [AuthGuard, AdminGuard] },
  { path: 'enquiries-management', loadChildren: './management-enquiries/management-enquiries.module#ManagementEnquiriesModule', canLoad: [AuthGuard, AdminGuard] },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsModule', canLoad: [AuthGuard, AdminGuard] },
  { path: 'register', loadChildren: './register/register.module#RegisterModule', canActivate: [GuestGuard] },
  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule', canActivate: [GuestGuard] },
  { path: 'change-password', loadChildren: './change-password/change-password.module#ChangePasswordModule', canActivate: [GuestGuard] },
  { path: 'terms-of-use', loadChildren: './terms-of-use/terms-of-use.module#TermsOfUseModule' },
  { path: 'impressum', loadChildren: './impressum/impressum.module#ImpressumModule' },
  { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyModule' },
  { path: 'help', loadChildren: './help/help.module#HelpModule' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
