// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import ngnPropertiesJson from '../assets/properties/ngn.properties.json';
import bmkkPropertiesJson from '../assets/properties/bmkk.properties.json';
import yplayPropertiesJson from '../assets/properties/yplay.properties.json';
import testPropertiesJson from '../assets/properties/test.properties.json'
export const environment = {
  production: false,
  // api: 'https://yplay-api.trasseninfo.de',
  // api: 'https://ta-test-api.ngn-itsolutions.eu',
  // api: 'https://breitband-mkk-api.trasseninfo.de',
  // api: 'https://ta-mkk-test-api.ngn-itsolutions.eu',
  api: 'https://ngn-api.trasseninfo.de',
  properties: ngnPropertiesJson
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
